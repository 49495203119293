import type { FC } from "react";
import { Outlet } from "react-router-dom";

const ViewerLayout: FC = function () {
  return (
    <div className="bg-black">
      <Outlet />
    </div>
  );
};

export default ViewerLayout;
