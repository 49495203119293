import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import { lazy, Suspense, useState } from "react";
import SpinnerCentered from "./components/manager/spinner-centered";
import "./App.css";
import { AuthUserProvider } from "./context/auth.context";

import ViewerLayout from "./pages/viewer/_layout";
import ViewerPage from "./pages/viewer/viewer";
import { PageContext } from "./context/page.context";

// Lazy Load Layouts
const LazyManagerLayout = lazy(() => import("./pages/manager/_layout"));
const LazyPanelLayout = lazy(() => import("./pages/manager/panel/_layout"));
const LazyAuthenticationLayout = lazy(
  () => import("./pages/manager/authentication/_layout")
);
const LazyBrandLayout = lazy(
  () => import("./pages/manager/panel/brand/_layout")
);
const LazyExperienceLayout = lazy(
  () => import("./pages/manager/panel/brand/experience/_layout")
);

// Lazy Load Pages
const LazySignInPage = lazy(
  () => import("./pages/manager/authentication/sign-in")
);
const LazySignUpPage = lazy(
  () => import("./pages/manager/authentication/sign-up")
);
const LazyForgotPasswordPage = lazy(
  () => import("./pages/manager/authentication/forgot-password")
);
const ResetPasswordPage = lazy(
  () => import("./pages/manager/authentication/reset-password")
);
const LazyUserAccountPage = lazy(
  () => import("./pages/manager/panel/user-account")
);
const LazySystemSettingsPage = lazy(
  () => import("./pages/manager/panel/system-settings")
);
const LazySupportPage = lazy(() => import("./pages/manager/panel/support"));

const LazyBrandsPage = lazy(() => import("./pages/manager/panel"));

const LazyBrandIndexPage = lazy(
  () => import("./pages/manager/panel/brand/index")
);

// Experience Admin Pages
const LazyQrCodeSchedulerPage = lazy(
  () => import("./pages/manager/panel/brand/experience/qr-code-scheduler")
);
const LazyAnalyticsPage = lazy(
  () => import("./pages/manager/panel/brand/experience/analytics")
);
const LazyPagesPage = lazy(
  () => import("./pages/manager/panel/brand/experience/pages")
);
const LazyScenesPage = lazy(
  () => import("./pages/manager/panel/brand/experience/scenes")
);
const LazyAssetsPage = lazy(
  () => import("./pages/manager/panel/brand/experience/assets")
);

const LazyNotFoundPage = lazy(() => import("./pages/not-found"));
const LazyLogoutPage = lazy(() => import("./pages/manager/logout"));

const router = createBrowserRouter([
  {
    path: "/manager",
    element: (
      <Suspense fallback={<SpinnerCentered />}>
        <LazyManagerLayout />
      </Suspense>
    ),
    children: [
      /**
       * Logout
       */
      {
        path: "logout",
        element: (
          <Suspense fallback={<SpinnerCentered />}>
            <LazyLogoutPage />
          </Suspense>
        ),
      },
      /**
       * Authentication
       */
      {
        path: "authentication?",
        element: (
          <Suspense fallback={<SpinnerCentered />}>
            <LazyAuthenticationLayout />
          </Suspense>
        ),
        children: [
          {
            path: "sign-in?",
            element: (
              <Suspense fallback={<SpinnerCentered />}>
                <LazySignInPage />
              </Suspense>
            ),
          },
          {
            path: "sign-up",
            element: (
              <Suspense fallback={<SpinnerCentered />}>
                <LazySignUpPage />
              </Suspense>
            ),
          },
          {
            path: "forgot-password",
            element: (
              <Suspense fallback={<SpinnerCentered />}>
                <LazyForgotPasswordPage />
              </Suspense>
            ),
          },
          {
            path: "reset-password",
            element: <ResetPasswordPage />,
          },
        ],
      },
      /**
       * Panel
       */
      {
        path: "panel",
        element: (
          <Suspense fallback={<SpinnerCentered />}>
            <LazyPanelLayout />
          </Suspense>
        ),
        children: [
          {
            path: "account",
            element: (
              <Suspense fallback={<SpinnerCentered />}>
                <LazyUserAccountPage />
              </Suspense>
            ),
          },
          {
            path: "settings",
            element: (
              <Suspense fallback={<SpinnerCentered />}>
                <LazySystemSettingsPage />
              </Suspense>
            ),
          },
          {
            path: "support",
            element: (
              <Suspense fallback={<SpinnerCentered />}>
                <LazySupportPage />
              </Suspense>
            ),
          },
          {
            path: "brands?",
            element: (
              <Suspense fallback={<SpinnerCentered />}>
                <LazyBrandsPage />
              </Suspense>
            ),
          },
          /**
           *  Brand
           */
          {
            path: "brands/:brandId",
            element: (
              <Suspense fallback={<SpinnerCentered />}>
                <LazyBrandLayout />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={<SpinnerCentered />}>
                    <LazyBrandIndexPage />
                  </Suspense>
                ),
              },
              /**
               * Experience
               */
              {
                path: "experience/:experienceId",
                element: (
                  <Suspense fallback={<SpinnerCentered />}>
                    <LazyExperienceLayout />
                  </Suspense>
                ),
                children: [
                  {
                    path: "scenes?",
                    element: (
                      <Suspense fallback={<SpinnerCentered />}>
                        <LazyScenesPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: "analytics",
                    element: (
                      <Suspense fallback={<SpinnerCentered />}>
                        <LazyAnalyticsPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: "pages",
                    element: (
                      <Suspense fallback={<SpinnerCentered />}>
                        <LazyPagesPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: "qr-code-scheduler",
                    element: (
                      <Suspense fallback={<SpinnerCentered />}>
                        <LazyQrCodeSchedulerPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: "assets",
                    element: (
                      <Suspense fallback={<SpinnerCentered />}>
                        <LazyAssetsPage />
                      </Suspense>
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/",
    element: <ViewerLayout />,
    errorElement: (
      <Suspense fallback={<SpinnerCentered />}>
        <LazyNotFoundPage />
      </Suspense>
    ),
    children: [
      {
        path: "", //catch all which should show a default experience
        element: <ViewerPage />,
      },
      {
        path: "experience/:experienceId/placement/:placementId",
        element: <ViewerPage />,
      },
    ],
  },
]);

function App() {
  const [pageTitle, setPageTitle] = useState<string>("");

  return (
    <AuthUserProvider>
      <PageContext.Provider value={{ pageTitle, setPageTitle }}>
        <RouterProvider router={router} />
      </PageContext.Provider>
    </AuthUserProvider>
  );
}

export default App;
