import type { FC } from "react";

const ViewerPage: FC = function () {
  return (
    <div className="viewer-page">
      <div className="flex min-h-screen flex-col items-center justify-center py-16">
        <h1 className="mb-6 text-2xl font-bold text-white md:text-5xl">
          Viewer
        </h1>
      </div>
    </div>
  );
};

export default ViewerPage;
