import { onAuthStateChanged, User } from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../firebase/firebase";

export default function useFirebaseAuth() {
  const [authUser, setAuthUser] = useState<User | null>(null);
  const [authIsLoading, setAuthIsLoading] = useState(true);

  const clear = () => {
    setAuthUser(null);
    setAuthIsLoading(false);
  };

  const authStateChanged = async (user: any) => {
    setAuthIsLoading(true);

    if (!user) {
      clear();
      return;
    }

    setAuthUser(user);
    setAuthIsLoading(false);
  };

  const signOut = () => auth.signOut().then(() => clear());

  // Listen for Firebase Auth state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, authStateChanged);
    return () => unsubscribe();
  }, []);

  return {
    authUser,
    authIsLoading,
    signOut,
  };
}

const AuthUserContext = createContext<{
  authUser: User | null;
  authIsLoading: boolean;
  signOut: () => Promise<void>;
}>({
  authUser: null,
  authIsLoading: true,
  signOut: async () => {},
});

export function AuthUserProvider({ children }: any) {
  const auth = useFirebaseAuth();
  return (
    <AuthUserContext.Provider value={auth}>{children}</AuthUserContext.Provider>
  );
}

export const useAuth = () =>
  useContext<{
    authUser: User | null;
    authIsLoading: boolean;
    signOut: () => Promise<void>;
  }>(AuthUserContext);
